/*=======  Testimonial  =======*/
.testimonial-box {
  background-color: $white;
  text-align: center;
  padding: 50px 30px;
  @include transition(0.3s);
  margin-bottom: 40px;

  @media #{$lg} {
    padding: 50px 20px;
  }

  @media #{$lsm} {
    padding: 50px 20px;
    font-size: 14px;
  }

  .client-img {
    max-width: 150px;
    max-height: 150px;
    margin: auto;
    position: relative;
    img {
      max-width: 150px;
      max-height: 150px;
      border-radius: 50%;
    }
    .check {
      position: absolute;
      right: 0;
      top: 0;
      border: 4px solid $white;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: $primary-color;
      text-align: center;
      line-height: 50px;
      color: $white;
    }
  }
  h3 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 35px;
    letter-spacing: -1px;
    @media #{$lg} {
      font-size: 26px;
    }
    @media #{$lsm} {
      font-size: 22px;
    }
  }
  .clinet-post {
    font-weight: bold;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 30px;
    letter-spacing: 2px;
    @media #{$lg} {
      font-size: 14px;
    }
    @media #{$lg} {
      font-size: 12px;
    }
  }
  &:hover {
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
  }
}

.amenity-box {
  background-color: $white;
  text-align: center;
  padding: 50px 30px;
  @include transition(0.3s);
  margin-bottom: 40px;

  @media #{$lg} {
    padding: 50px 20px;
  }

  @media #{$lsm} {
    padding: 50px 20px;
    font-size: 14px;
  }

  .client-img {
    max-width: 150px;
    max-height: 150px;
    margin: auto;
    position: relative;
    img {
      max-width: 150px;
      max-height: 150px;
      border-radius: 50%;
    }
    .check {
      position: absolute;
      right: 0;
      top: 0;
      border: 4px solid $white;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: $primary-color;
      text-align: center;
      line-height: 50px;
      color: $white;
    }
  }
  h3 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 35px;
    letter-spacing: -1px;
    @media #{$lg} {
      font-size: 26px;
    }
    @media #{$lsm} {
      font-size: 22px;
    }
  }
  .clinet-post {
    font-weight: bold;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 30px;
    letter-spacing: 2px;
    @media #{$lg} {
      font-size: 14px;
    }
    @media #{$lg} {
      font-size: 12px;
    }
  }
  &:hover {
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
  }
}

.testimonial-slider {
  .slick-dots li ul.slick-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    li {
      display: inline-block;
      cursor: pointer;
      button {
        font-size: 0;
        border: 0;
        background: #e7e7e7;
        width: 16px;
        height: 4px;
        margin: 0 5px;
        @include transition(0.3s);
      }
      button:before {
        content: "" !important;
      }
      &.slick-active {
        button {
          width: 26px;
          background-color: $primary-color;
        }
      }
    }
  }
}

.amenity-slider {
  ul.slick-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    li {
      display: inline-block;
      cursor: pointer;
      button {
        font-size: 0;
        border: 0;
        background: #e7e7e7;
        width: 16px;
        height: 4px;
        margin: 0 5px;
        @include transition(0.3s);
      }
      &.slick-active {
        button {
          width: 26px;
          background-color: $primary-color;
        }
      }
    }
  }
}
