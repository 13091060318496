/*=======  BOOKING FORM  =======*/
.booking-form {
	position: relative;
	margin-top: -70px;
	z-index: 2;
	.booking-form-inner {
		background-color: $white;
		padding: 10px 40px 40px;
	}
	form {
		input,
		select,
		.nice-select {
			background-color: transparent;
			width: 100%;
			height: 50px;
			font-size: 14px;
			border: none;
			border-bottom: 2px solid $primary-color;
			line-height: 50px;
			color: rgba($color: $text-color, $alpha: 0.5);
		}
		.nice-select {
			border-color: #e9e9e9;
			padding-left: 0;
			&::after {
				display: none;
			}
			&::before {
				left: 0;
			}
		}
		label {
			color: $heading-color;
			font-weight: 700;
			font-family: $roboto;
			display: block;
			margin: 0;
		}

		.inputs-filed {
			position: relative;
			input,
			.nice-select {
				padding: 0 20px;
			}

			.icon {
				position: absolute;
				left: 0;
				bottom: 15px;
				font-size: 14px;
				color: $primary-color;
			}
		}

		button {
			width: 100%;
			border: none;
			background-color: $primary-color;
			font-size: 14px;
			font-weight: 700;
			color: $white;
			text-transform: uppercase;
			height: 60px;
			letter-spacing: 2px;
			@include transition(0.3s);
			&:hover {
				background-color: $black;
			}

			@media #{$lg} {
				font-size: 12px;
				letter-spacing: 1px;
			}
		}
	}

	&.booking-form-dark {
		background-color: $heading-color;
		margin-top: 0;
		.booking-form-inner {
			background-color: transparent;
		}
		form label {
			color: $white;
		}
	}

	&.boxed-layout {
		margin-top: -40px;
		@media #{$md} {
			margin-top: 0;
		}
		@media #{$sm} {
			margin-top: 0;
		}
		form {
			button {
				font-size: 12px;
				letter-spacing: 1;
			}
		}
	}
}
