a:hover {
  text-decoration: none;
}
/*| Navigation |*/

/* .my-nav {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #777;
  font-size: 16px;
  line-height: 1.6;
  background-color: white !important;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.3em;
  top: 0px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
} */

.my-nav {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #777;
  font-size: 16px;
  line-height: 1.6;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  height: 60px !important; /* Set a fixed height for the navigation bar */
  background: #fff;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  font-size: 1.3em;
  z-index: 1000; /* Ensure the .my-nav stays on top */
}

.links {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #777;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 2%;
  cursor: pointer;
}
ul {
  list-style: none;
  text-align: center;
  position: relative;
  /* float: right; */
  /* I removed this to remove the  overall shadow */
  /* margin-right: 100px; */
  display: inline-table;
  z-index: 2;
}
ul li {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #777;
  font-size: 16px;
  line-height: 1.6;
  /* float: left; */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

ul li:hover {
  text-decoration: none;
}
ul li:hover > ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-decoration: none;
  padding-right: 0.5vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1vh;
  /* position: relative; */
  /* right: 10px; */
}
ul li {
  float: left;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
ul li a {
  display: block;
  padding: 5px 20px;
  color: #222;
  text-align: center;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
ul ul {
  display: none;
  background: #fff;
  position: absolute;
  /* top: 100%; */
  box-shadow: -3px 3px 10px -2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

ul ul li {
  float: none;
  width: 100%;

  /* display: none; */
  position: relative;
}
ul ul li a {
  padding: 15px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
}
@media only screen and (max-width: 501px) {
  .navHeader {
    margin-top: 60px;
  }

  ul ul {
    display: none;
    /* display: flex; */
    flex-direction: column;
    /* position: relative; */
    /* margin-bottom: 100px; */
  }
  .main {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 100px; */
    /* padding-bottom: 100px; */
    flex-wrap: wrap;
  }
  .img {
    margin-left: 10px;
  }
  ul li:hover > ul {
    padding-right: 8%;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  .navHeader {
    margin-top: 60 !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 1124px) {
  .navHeader {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 1124px) {
  .my-nav {
    font-size: 1rem;
    height: 60px !important; /* Set a fixed height for the navigation bar */
  }
}
@media only screen and (max-width: 998px) {
  .my-nav {
    font-size: 1rem;
    height: 30vh;
  }
}
@media only screen and (max-width: 862px) {
  .my-nav {
    font-size: 1rem;
    height: 45vh;
  }
}
@media only screen and (max-width: 507px) {
  .my-nav {
    font-size: 1rem;
    height: 30vh;
  }
  ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    /* background-color: orange   */
  }
}
@media only screen and (max-width: 378px) {
  .my-nav {
    font-size: 1rem;
    /* height:75vh; */
  }
}
@media only screen and (max-width: 293px) {
  .my-nav {
    font-size: 1rem;
    /* height:90vh; */
  }
}
@media only screen and (max-width: 273px) {
  .my-nav {
    font-size: 1rem;
    /* height:100vh; */
  }
}
@media only screen and (max-width: 245px) {
  .my-nav {
    font-size: 1rem;
    /* height:120vh; */
  }
}
