/*=======  BANNER CSS  =======*/
.banner-area {
  .banner-content {
    span.promo-tag {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 3px;
      padding-bottom: 30px;
      @media #{$md} {
        font-size: 12px;
        letter-spacing: 1px;
      }
      @media #{$sm} {
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
    .title {
      font-size: 120px;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: -6px;
      padding-bottom: 26px;
      @media #{$xl} {
        font-size: 100px;
      }
      @media #{$lg} {
        font-size: 86px;
      }
      @media #{$lsm} {
        font-size: 72px;
      }
      @media #{$xsm} {
        font-size: 66px;
      }
      @media #{$tiny} {
        font-size: 52px;
      }
    }
    ul {
      li {
        display: inline-block;
        &:last-child {
          margin-left: 24px;
          @media #{$lg} {
            margin-left: 10px;
          }
          @media #{$md} {
            margin-left: 10px;
          }
          @media #{$sm} {
            margin-left: 5px;
          }
          @media #{$tiny} {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
    .main-btn {
      @media #{$lsm} {
        padding: 0 35px;
        line-height: 55px;
      }
      @media #{$xsm} {
        padding: 0 25px;
        line-height: 50px;
        letter-spacing: 1px;
      }
    }
  }

  .banner-thumb {
    .single-thumb {
      overflow: hidden;
      img {
        animation: zoomIn 15s linear infinite;
      }
    }
  }

  &.banner-style-one {
    padding-top: 240px;
    @media #{$lg} {
      padding-top: 200px;
    }
    @media #{$md} {
      padding-top: 160px;
      padding-bottom: 120px;
    }

    @media #{$sm} {
      padding-top: 160px;
      padding-bottom: 100px;
    }

    .banner-content {
      @media #{$xl} {
        padding-bottom: 100px;
      }
      @media #{$lg} {
        padding-bottom: 100px;
      }
      .title {
        @media #{$md} {
          font-size: 66px;
        }
      }
      ul li:last-child {
        @media #{$md} {
          margin-left: 5px;
        }
      }
      .main-btn {
        @media #{$md} {
          padding: 0 20px;
          line-height: 50px;
          letter-spacing: 1px;
        }
      }
    }
  }

  &.banner-style-two,
  &.banner-style-three {
    .single-banner {
      height: 950px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      @media #{$sm} {
        height: 700px;
      }
      .banner-bg,
      .banner-overly {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        background-size: cover;
        background-position: center;
      }
      .banner-overly {
        background-color: $heading-color;
        z-index: -1;
        opacity: 0.6;
      }
      .banner-bg {
        animation: zoomIn 15s linear infinite;
      }
    }
    .banner-content {
      &,
      h1 {
        color: $white;
      }
      h1 {
        @media #{$md} {
          font-size: 90px;
        }
      }
      .main-btn {
        &.btn-filled,
        &.btn-border:hover {
          box-shadow: none;
        }
        &.btn-filled:hover,
        &.btn-border {
          border-color: rgba($color: #fff, $alpha: 0.2);
          color: $white;
        }
      }
    }
  }

  &.banner-style-three {
    .single-banner {
      height: auto;
      padding-top: 180px;
      padding-bottom: 125px;
    }
  }

  .slick-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background: $white;
    line-height: 50px;
    text-align: center;
    font-size: 17px;
    cursor: pointer;
    transition: 0.3s;
    z-index: 9;
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
    &.next-arrow {
      right: 0;
      left: auto;
    }
  }

  ul.slick-dots {
    position: absolute;
    margin-top: 1000px;
    left: 50%;
    transform: translateX(-51%);
    bottom: 30px;
    li {
      display: inline-block;
      margin: 0 5px;
      button {
        border: none;
        font-size: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        line-height: 0;
        font-size: 0;
      }
      &.slick-active {
        button {
          background-color: $primary-color;
        }
      }
    }
  }
}
